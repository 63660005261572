import { FacebookOutlined } from '@mui/icons-material';
import { ClientContext } from "contexts/ClientContext";
import { useContext } from "react";
import "./Facebook.css";

const Facebook = () => {
    const { formData } = useContext(ClientContext);

    return (
        <div className="facebook-buton">
            <a className="cc-calto-action-ripplee" target='_blank' href="https://www.facebook.com/vehiclesolutionbd?mibextid=ZbWKwL"><FacebookOutlined fontSize='large' />
            </a>
        </div>
    )
}

export default Facebook