import { gql, useMutation } from "@apollo/client";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Card } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { ClientContext } from "contexts/ClientContext";
import MKButton from "layouts/basic/components/MKButton";
import MKInput from "layouts/basic/components/MKInput";
import MKTypography from "layouts/basic/components/MKTypography";
import { useContext, useState } from "react";
import { clientConfiguration } from "settings/Configuration";
import MKBox from "../../../../components/MKBox";
import ContactLocationMap from "./contact-one-sections/ContactLocationMap";
// import ContactLocationMap from "./contact-one-sections/ContactLocationMap";
// Images
const POST_MESSAGE = gql`
mutation($emailDetail: EmailNotificationInput){
  sendEmailNotification(emailDetail: $emailDetail){
    success
  }
}`;

export function getHostName() {
  let url = window.location;
  let host = url.hostname;
  if (host === "localhost") {
    host = clientConfiguration.host;
  }
  return host;
}


function ContactUsOne(props) {
  const { sliderText, pageContents } = props;
  const { formData } = useContext(ClientContext);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const contactNumber =
    formData &&
    formData.map((e) => {
      return e.ContactNumber;
    });
  const contactEmail =
    formData &&
    formData.map((e) => {
      return e.ContactEmail;
    });
  const address =
    formData &&
    formData.map((e) => {
      return e.Template;
    });

  // useEffect(() => { }, []);

  const [sendMessage] = useMutation(POST_MESSAGE, {
    onCompleted: (d) => {
      if (d && d.sendEmailNotification && d.sendEmailNotification.success) {
        alert("Successfully sent!");
        setFirstName("");
        setLastName("");
        setEmail("");
        setMessage("");
      }
    },
    onError: (e) => {
      alert(e);
    }
  })
  const validateEmail = (emailV) => {
    return String(emailV)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const isValid = () => {
    return firstName.length > 0 && lastName.length > 0 && message.length > 0 && email.length > 0 && validateEmail(email)
  }
  const handleSendMessage = () => {
    if (!isValid()) {
      return;
    }
    sendMessage({
      variables: {
        "emailDetail": {
          "message": message,
          "subject": "contact-form email",
          "sender": {
            "firstName": firstName,
            "lastName": lastName,
            "email": email
          },
          "receiver": {
            "firstName": "Admin",
            "lastName": "",
            "email": "service.vehiclesolution@gmail.com"
          }
        }
      }
    })
  }

  console.log(getHostName())
  return (
    <>
      <MKBox
        minHeight="40vh"
        width="100%"
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${`https://xeonstorage.blob.core.windows.net/dynamic-site-data/contact-cover.jpg`})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h2"
              color="white"
              mb={2}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              {sliderText}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>

      {/* <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
            rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          overflow: "hidden",
        }}
      > */}
      {/* <MKBox component="section" py={6}>
        <Container>
          <Grid container alignItems="center" spacing={3}>
            {pageContents.map((e, i) => {
              if (e.ContentType === "2") {
                return (
                  <>
                    <Grid
                      key={`${i}-contact-us`}
                      item
                      sm={12}
                      lg={e.GridSize}
                      container
                      direction="column"
                      justifyContent={e.GridContainerProps}
                      alignItems={e.GridContainerProps}
                    >
                      <RaisedBlogCard
                        image={e.ImageUrl}
                        title={e.Title}
                        description={e.Description}
                        textAlign={e.GridContainerProps}
                      // action={{
                      //   type: "internal",
                      //   route: "/sections/page-sections/general-cards",
                      //   color: "info",
                      //   label: "More about us",
                      // }}
                      />
                    </Grid>
                  </>
                )
              }
              else if (e.ContentType === "3") {
                return (
                  <>
                    <Grid
                      key={`${i}-home-one`}
                      item
                      sm={12}
                      lg={e.GridSize}
                      container
                      direction="column"
                      justifyContent={e.GridContainerProps}
                      alignItems={e.GridContainerProps}
                    >
                      <BasicCard
                        src={e.ImageUrl}
                        title={e.Title}
                        description={e.Description}
                        textAlign={e.GridContainerProps}
                      />
                    </Grid>
                  </>
                )
              }
              else {
                if (
                  e.GridContainerProps === "center" ||
                  e.GridContainerProps === "flex-end"
                ) {
                  return (
                    <>
                      <Grid
                        key={`${i}-home-one`}
                        item
                        sm={12}
                        lg={e.GridSize}
                        container
                        direction="column"
                        justifyContent={e.GridContainerProps}
                        alignItems={e.GridContainerProps}
                      >
                        <MKTypography variant={e.HeaderType} my={1}>
                          {e.Title}
                        </MKTypography>
                        <MKTypography
                          variant="body2"
                          color="text"
                          mb={3}
                          textAlign="justify"
                        >
                          {e.Description}
                        </MKTypography>
                        {e.ImageUrl && (
                          <MKBox position="relative" my={5}>
                            <MKBox
                              component="img"
                              src={e.ImageUrl}
                              alt="macbook"
                              width="100%"
                            />
                          </MKBox>
                        )}
                      </Grid>
                    </>
                  );
                }
                else {
                  return (
                    <>
                      <Grid
                        key={`${i}-home-one`}
                        item
                        sm={12}
                        lg={e.GridSize}
                        direction="column"
                        container
                        justifyContent="flex-start"
                      >
                        <MKTypography variant="h3" my={1}>
                          {e.Title}
                        </MKTypography>
                        <MKTypography
                          variant="body2"
                          color="text"
                          mb={3}
                          textAlign="justify"
                        >
                          {e.Description}
                        </MKTypography>
                        {e.ImageUrl && (
                          <MKBox position="relative" my={5}>
                            <MKBox
                              component="img"
                              src={e.ImageUrl}
                              alt="macbook"
                              width="100%"
                            />
                          </MKBox>
                        )}
                      </Grid>
                    </>
                  );
                }
              }
            })}
          </Grid>
        </Container>
      </MKBox> */}
      {/* <Grid container>
        <ContactForm />
      </Grid> */}
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
            rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          overflow: "hidden",
        }}
      >
        <MKBox
          component="section"
          display="grid"
          position="relative"
          minHeight="90vh"
          borderRadius="xl"
          mt={10}
          mr={{ xs: 0, lg: -2 }}
          mb={{ xs: 0, lg: -2 }}
          sx={{
            overflow: { xs: "hidden", lg: "visible" },
            placeItems: "center",
          }}
        >
          <MKBox
            component="img"
            src="https://xeonstorage.blob.core.windows.net/dynamic-site-data/contact_us.png"
            alt="car"
            width={{ xs: "100%", lg: "50%" }}
            height={{ xs: "100%", lg: "100%" }}
            position="absolute"
            right={0}
            bottom={{ xs: "-25%", lg: "unset" }}
            top={{ xs: 0, lg: "unset" }}
            sx={{
              objectFit: "cover",
              borderTopLeftRadius: ({ borders: { borderRadius } }) => ({
                xs: 0,
                lg: borderRadius.lg,
              }),
            }}
          />
          <Container>
            <Grid
              container
              spacing={{ xs: 0, lg: 3 }}
              sx={{ mt: { xs: 0, lg: 12 } }}
              ml={-1}
            >
              <Grid
                item
                xs={12}
                lg={7}
                justifyContent="center"
                flexDirection="column"
              >
                <MKBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  shadow="lg"
                  borderRadius="xl"
                  sx={{
                    backgroundColor: ({
                      functions: { rgba },
                      palette: { white },
                    }) => rgba(white.main, 0.8),
                    backdropFilter: "saturate(200%) blur(30px)",
                    px: { xs: 3, sm: 6 },
                    py: { xs: 3, sm: 8 },
                    mb: { xs: 0, lg: 8 },
                    mt: { xs: 0, lg: -6 },
                  }}
                >
                  <MKBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height="100%"
                  >
                    <MKBox py={6} pl={6} pr={{ xs: 6, sm: 12 }} my="auto">
                      <MKTypography
                        variant="h3"
                        textAlign="center"
                        color="black"
                        mb={2}
                      >
                        Contact Information
                      </MKTypography>

                      {getHostName() === "www.vehiclesolution.net" &&

                        <>

                          <MKTypography
                            variant="body2"
                            color="black"
                            opacity={0.8}
                            mb={3}
                          >
                            Fill up the form and our Team will get back to you soon.
                          </MKTypography>
                          <Grid container spacing={2}>
                            <Grid item lg={5} md={5} sm={6} xs={6} mt={2}>
                              <MKInput
                                variant="outlined"
                                label="First Name"
                                fullWidth
                                value={firstName}
                                type="text"
                                onChange={(e) => { setFirstName(e.target.value) }}
                              />
                            </Grid>
                            <Grid item lg={5} md={5} sm={6} xs={6} mt={2}>
                              <MKInput
                                variant="outlined"
                                label="Last Name"
                                fullWidth
                                value={lastName}
                                type="text"
                                onChange={(e) => { setLastName(e.target.value) }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item lg={10} md={10} sm={6} xs={6} mt={2}>
                              <MKInput
                                variant="outlined"
                                label="Email"
                                fullWidth
                                value={email}
                                type="email"
                                onChange={(e) => { setEmail(e.target.value) }}
                              />
                              {
                                !validateEmail(email) && email.length > 1 &&
                                <h6><span style={{ color: 'red', marginLeft: 10 }}>Enter valid email!</span></h6>
                              }
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item lg={10} md={10} sm={6} xs={6} mt={2}>
                              <MKInput
                                variant="outlined"
                                label="Message"
                                multiline
                                rows={4}
                                fullWidth
                                value={message}
                                onChange={(e) => { setMessage(e.target.value) }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item lg={8} md={8} sm={6} xs={6} mt={2} mb={3}>
                              <MKButton variant="contained" color="primary" onClick={handleSendMessage} disabled={!isValid()}>Send</MKButton>
                            </Grid>
                          </Grid>

                        </>

                      }


                      <MKBox display="flex" p={1}>
                        <MKTypography
                          variant="button"
                          color="black"
                          sx={{ marginTop: "3px" }}
                        >
                          <CallIcon fontSize="small" />
                        </MKTypography>
                        <MKTypography
                          component="span"
                          variant="button"
                          color="black"
                          opacity={0.8}
                          ml={2}
                          fontSize={16}
                          fontWeight="regular"
                        >
                          {contactNumber}
                        </MKTypography>
                      </MKBox>
                      <MKBox display="flex" p={1}>
                        <MKTypography
                          variant="button"
                          color="black"
                          sx={{ marginTop: "2px" }}
                        >
                          <EmailIcon fontSize="small" />
                        </MKTypography>
                        <MKTypography
                          component="span"
                          variant="button"
                          color="black"
                          opacity={0.8}
                          ml={2}
                          fontSize={16}
                          fontWeight="regular"
                        >
                          {contactEmail}
                        </MKTypography>
                      </MKBox>
                      <MKBox display="flex" color="black" p={1}>
                        <MKTypography
                          variant="button"
                          color="black"
                          sx={{ marginTop: "10px" }}
                        >
                          <LocationOnIcon fontSize="small" />
                        </MKTypography>
                        <MKTypography
                          component="span"
                          variant="button"
                          color="black"
                          opacity={0.8}
                          ml={2}
                          fontSize={16}
                          fontWeight="regular"
                        >
                          {address}
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
        <Grid sx={{ mt: 15 }}>
          <ContactLocationMap />
        </Grid>
      </Card>
    </>
  );
}
export default ContactUsOne;
