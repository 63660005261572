export function getCellValue(properties, name) {
  let value = "";
  let cell = properties.find((a) => a.name === name);
  if (cell) {
    value = cell.value;
  }
  return value;
}

export function getSimplifiedObject(dynamoDbObj) {
  let resultObj = {};
  if (dynamoDbObj) {
    for (let i = 0; i < dynamoDbObj.properties.length; i++) {
      let p = dynamoDbObj.properties[i];
      resultObj = { ...resultObj, [p.name]: p.value };
    }
  }
  return resultObj;
}

export function getDynamoPagedItems(res){
  let items = []
  if(res){
    let dItems = res.objectList && res.objectList.items && res.objectList.items ? res.objectList && res.objectList.items && res.objectList.items: []
    for(let i = 0; i < dItems.length; i ++){
      let simpleItem = getSimplifiedObject(dItems[i])
      items.push(simpleItem)
    }
  }
  return items
}