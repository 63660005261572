import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import React from "react";
import { hydrate, render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { API_URL } from "./settings/ApiUrl";

const client = new ApolloClient({
  uri: API_URL,
  cache: new InMemoryCache(),
});

const renderMethod = !!module.hot ? render : hydrate;

renderMethod(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById("root")
);

if (module.hot) {
  module.hot.accept();
}
