import { useLazyQuery } from "@apollo/client";
import {
  GET_OBJECT_LIST_QUERY, GQL_PAGES_GET_VARIABLE,
  GQL_PAGE_CONTENT_GET_VARIABLE
} from "constants/gql-constants";
import { ClientContext } from "contexts/ClientContext";
import { getDynamoPagedItems } from "lib/dynamo-db-helper";
import { useContext, useEffect, useState } from "react";
import LoaderOne from "../layouts/basic/loader/LoaderOne";
import ContactUsOne from "../layouts/basic/pages/ContactUs/Designs/DesignOne/ContactUsOne";

function ContactUs() {
  const [design, setDesign] = useState(1);

  const [pageObj, setPageObj] = useState(null);
  const [title, setTitle] = useState("");
  const [sliderText, setSliderText] = useState("");
  const [pageContents, setPageContents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetchedPages, setIsFetchedPages] = useState(false);
  const { dbId, menuName } = useContext(ClientContext);

  useEffect(() => {
    getPageObject();
  }, []);

  useEffect(() => {
    getPageContentObject();
  }, [isFetchedPages]);

  var items = window.location.pathname;
  var path = items.split('/')
  var pathname = path[path.length-1]
  
  const pages = menuName && menuName.find(e => e.RoutePath === pathname);

  const [getPageObject] = useLazyQuery(GET_OBJECT_LIST_QUERY, {
    variables: {
      request: {
        tableName: "pages",
        filters: [
          {
            name: "pageName",
            value: pages.MenuName,
            type: "STRING",
            operator: "LIKE",
          },
        ],
        databaseId: dbId,
        properties: GQL_PAGES_GET_VARIABLE,
      },
    },
    onCompleted: (d) => {
      if (d) {
        let items = getDynamoPagedItems(d);
        let obj = items[items.length - 1];

        if (obj) {
          setTitle(obj.Title);
          setSliderText(obj.HeaderImageText);
        }
        setPageObj(obj);
        setIsFetchedPages(true);
      }
    },
    onError: (e) => {
      console.log(e);
      setPageObj(null);
      setIsFetchedPages(true);
    },
  });

  const [getPageContentObject] = useLazyQuery(GET_OBJECT_LIST_QUERY, {
    variables: {
      request: {
        tableName: "pageContent",
        pageSize: 1000,
        filters: [
          {
            name: "pageName",
            value: pages.MenuName,
            type: "STRING",
            operator: "LIKE",
          },
        ],
        databaseId: dbId,
        properties: GQL_PAGE_CONTENT_GET_VARIABLE,
      },
    },
    onCompleted: (d) => {
      if (d) {
        let items = getDynamoPagedItems(d);
        let st = items.sort(
          (a, b) => parseInt(a.ContentSerial) - parseInt(b.ContentSerial)
        );
        setPageContents(st);
        setIsLoading(false);
      }
    },
    onError: (e) => {
      console.log(e);
      setIsLoading(false);
    },
  });
  const handleSplitMapData = (a) => {
    let result = a.split('|');
    return result;
  }
  console.log("Data", pageContents)

  return (
    <>
      {isLoading && <LoaderOne />}

      {!isLoading && (
        <>
          {design === 1 && (
            <ContactUsOne
              pageObject={pageObj}
              loading={isLoading}
              title={title}
              sliderText={sliderText}
              pageContents={pageContents}
            />
          )}
        </>
      )}
    </>
  );
}

export default ContactUs;
