let envType = 1;

function ParseBaseUrl() {
  let url = "";
  if (envType === 1) {
    url = "https://xeon-db-new.azurewebsites.net/graphql";
  } else {
    url = "https://192.168.88.19:44363/graphql";
  }
  return url;
}

export const API_URL = ParseBaseUrl();

// const isBrowser = typeof window !== "undefined";

// function ParseBaseUrl(){
//     const host = isBrowser && window.location.host;
//     if(host && host.startsWith("localhost")){
//         return "https://localhost:44363/graphql"
//     }else{
//         return "https://xeon-db-new.azurewebsites.net/graphql"
//     }

// }

//https://192.168.88.19:44363/graphql

//https://localhost:44363/graphql
