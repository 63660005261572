import { ClientContext } from "contexts/ClientContext";
import { useContext } from "react";
import {
  GoogleMap,
  Marker, withGoogleMap, withScriptjs
} from "react-google-maps";

export default function ContactLocationMap() {
  const { formData } = useContext(ClientContext);

  const dt = formData && formData.map((e) => { return e.GPSCoordinates })
  const data = dt && dt.toString()
  const gpsData = data && data.split('|')
  const lat = gpsData && gpsData[0]
  const lag = gpsData && gpsData[1]
  
  const loadingElementStyle = { height: '100%' };
  const containerElementStyle = { height: '550px' };
  const mapElementStyle = { height: '100%' };
  
  const RegularMap = withScriptjs(
    withGoogleMap(props => (
      <GoogleMap
        defaultZoom={18}
        defaultCenter={defaultCenter}
        defaultOptions={defaultOptions}
      >
        <Marker position={defaultCenter} />
      </GoogleMap>
    ))
  );
 
  const defaultCenter = { lat: parseFloat(lat), lng: parseFloat(lag) };
  const defaultOptions = { scrollwheel: false };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <RegularMap
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC6_RjvWyOAe6pM5FOlsm4uOlIQ479Jafk"
        loadingElement={<div style={loadingElementStyle} />}
        containerElement={<div style={containerElementStyle} />}
        mapElement={<div style={mapElementStyle} />}
      />
    </div>
  );
}