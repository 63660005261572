import { useLazyQuery } from "@apollo/client";
import { Grid, Typography } from "@mui/material";
import { GET_OBJECT_LIST_QUERY, GQL_SLIDER_CONTENT_GET_VARIABLE } from "constants/gql-constants";
import { ClientContext } from 'contexts/ClientContext';
import { getDynamoPagedItems } from "lib/dynamo-db-helper";
import { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
function ReactSlickSlider(props) {
    const { dbId, dbName, hostName, routesData } = useContext(ClientContext);
    const [sliderContents, setSliderContents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [isFetchedPages, setIsFetchedPages] = useState(true)

    const settings = {
        dots: true,
        vertical: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 1000,
        infinite: true,
        verticalSwiping: true,
        pauseOnHover: false,
        rtl: true
    };
    // const settings = {
    //     dots: true,
    //     arrows: false,
    //     infinite: true,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     autoplay: true,
    //     autoplaySpeed: 2000,
    //     pauseOnHover: true
    // };

    useEffect(() => {
        getPageContentObject();
    }, [isFetchedPages])

    const [getPageContentObject] = useLazyQuery(GET_OBJECT_LIST_QUERY, {
        variables: {
            request: {
                tableName: "slider",
                filters: [
                    {
                        name: "pageName",
                        value: "Home-Main-Slider",
                        type: "STRING",
                        operator: "LIKE",
                    },
                ],
                databaseId: dbId,
                properties: GQL_SLIDER_CONTENT_GET_VARIABLE,
            },
        },
        onCompleted: (d) => {
            if (d) {
                let items = getDynamoPagedItems(d);
                let st = items.sort((a, b) => parseInt(a.ContentSerial) - parseInt(b.ContentSerial));
                setSliderContents(st);
                setIsLoading(false);
            }
        },
        onError: (e) => {
            console.log(e);
            setIsLoading(false);
        },
    });

    return (
        <Slider {...settings}>
            {sliderContents.map((s, i) => {
                return (
                    <div key={`sld-${i}`}>
                        <Grid container display="flex" position="relative">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <img
                                    src={`${s.ImageUrl}`}
                                    style={{
                                        display: "flex",
                                        // opacity: "0.7",
                                        width: "100%",
                                        height: "auto",
                                        zIndex: -1
                                    }}
                                />
                            </Grid>
                            <Grid container position="absolute" display="flex" justifyContent="center" sx={{ top: "40%" }}>
                                <Grid item container justifyContent={"center"} md={12} lg={12} xs={12}>
                                    <Typography variant="h1" color="#FFE300" textAlign="center" mb={2} sx={{ fontSize: { lg: 98, md: 60, sm: 45, xs: 40 } }}>{s.SliderText}</Typography>
                                </Grid>
                                <Grid item container justifyContent={"center"} md={12} lg={12} xs={12}>
                                    <Typography variant="h1" color="#DBFF3D" textAlign="center" sx={{ fontSize: { lg: 35, md: 30, sm: 25, xs: 20 } }}>{s.SliderSubText}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                )
            })}

        </Slider>
    )
}
export default ReactSlickSlider