import { useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

// Material Kit 2 PRO React themes
import theme from "layouts/basic/assets/theme";

// @mui material components
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
//context
import { useLazyQuery } from "@apollo/client";

//Custom
import {
    GET_DB,
    GET_OBJECT_LIST_QUERY,
    GQL_GET_CONFIG_DATA,
    GQL_GET_ROUTES
} from "constants/gql-constants";
import { getDynamoPagedItems } from "lib/dynamo-db-helper";
import ContactUs from "pages/contact-us";
import DynamicPage from "pages/DynamicPage";
import Gallery from "pages/gallery";
import Home from "pages/Home";
import BasicLayout from "./layouts/basic/BasicLayout";
import { clientConfiguration } from "./settings/Configuration";

//context
import { ClientContext } from "./contexts/ClientContext";

export default function App() {
    const { pathname } = useLocation();

    //states
    const [hostName, setHostName] = useState("");
    const [dbId, setDbId] = useState("");
    const [routesData, setRoutesData] = useState([]);
    const [dbName, setDbName] = useState("");
    const [menuName, setMenuName] = useState("");
    const [formData, setFormData] = useState();
    //states

    useEffect(() => {
        let host = getHostName();
        setHostName(host);
    }, []);

    useEffect(() => {
        if (hostName) {
            console.log(`host-name: ${hostName}`)
            getDbInfo({
                variables: {
                    request: {
                        databaseName: hostName,
                    },
                },
            });
        }
    }, [hostName]);

    // Setting page scroll to 0 when changing the route. Note: this block added by theme
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);

    const [getDbInfo] = useLazyQuery(GET_DB, {
        onCompleted: (d) => {
            console.log(d)
            let db = d && d.database;
            if (db) {
                setDbId(db.id);
                setDbName(db.name);
            }
        },
        onError: (e) => {
            setDbId("");
            setDbName("");
        },
    });

    useEffect(() => {
        if (dbId) {
            getRoutesInfo();
            getSiteConfigObject();
        }
    }, [dbId]);

    const [getRoutesInfo] = useLazyQuery(GET_OBJECT_LIST_QUERY, {
        variables: {
            request: {
                pageSize: 1000,
                tableName: "routes",
                databaseId: dbId,
                properties: GQL_GET_ROUTES,
            },
        },
        onCompleted: (d) => {
            if (d) {
                let items = getDynamoPagedItems(d);
                let st = items.sort(
                    (a, b) => parseInt(a.ContentSerial) - parseInt(b.ContentSerial)
                );
                setMenuName(st)
                handleRoutes(st);
            }
        },
        onError: (e) => {
            console.log(e);
        },
    });

    const handleRoutes = (element) => {
        let data = [];
        element.map((e, i) => {
            data.push({
                key: e.MenuName,
                name: e.MenuName,
                route: e.RoutePath,
                parent: e.Parent,
                component: DynamicPage,
            });
        });
        setRoutesData(data);
    };

    function getHostName() {
        let url = window.location;
        let host = url.hostname;
        if (host === "localhost") {
            host = clientConfiguration.host;
        }
        return host;
    }

    const getRoutes = (e) => {
        let data = [];
        e.map((r, i) => {
            data.push(
                <Route path={`/${r.route}`} component={r.component} key={r.key} />
            );
        });
        return data;
    };


    const [getSiteConfigObject] = useLazyQuery(GET_OBJECT_LIST_QUERY, {
        variables: {
            request: {
                tableName: "siteConfigs",
                databaseId: dbId,
                properties: GQL_GET_CONFIG_DATA
            }
        },
        onCompleted: (d) => {
            if (d) {

                if (d) {
                    let items = getDynamoPagedItems(d)
                    setFormData(items);
                }
                else {
                    console.log(e);
                }
            }
        },
        onError: (e) => {
            console.log("error");
        },
    });

    return (
        <ClientContext.Provider value={{ dbId, dbName, hostName, routesData, menuName, formData }}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <BasicLayout>
                    <Switch>
                        <Route path="/home" component={Home} />
                        <Route path="/gallery" component={Gallery} />
                        <Route path="/contact-us" component={ContactUs} />
                        {getRoutes(routesData)}
                        <Redirect from="*" to="home" />
                    </Switch>
                </BasicLayout>
            </ThemeProvider>
        </ClientContext.Provider>
    );
}