import { useLazyQuery } from "@apollo/client";
import { GET_OBJECT_LIST_QUERY, GQL_PAGES_GET_VARIABLE, GQL_PAGE_CONTENT_GET_VARIABLE } from "constants/gql-constants";
import { ClientContext } from "contexts/ClientContext";
import LoaderOne from "layouts/basic/loader/LoaderOne";
import { getDynamoPagedItems } from "lib/dynamo-db-helper";
import { useContext, useEffect, useState } from "react";
import DynamicPageOne from "../layouts/basic/pages/DynamicPages/Design/DynamicPageOne";

function DynamicPage() {
  const [design, setDesign] = useState(1);
  const [pageObj, setPageObj] = useState(null);
  const [title, setTitle] = useState("");
  const [pageContentObject, setPageContentObj] = useState("");
  const [sliderText, setSliderText] = useState("");
  const [pageContents, setPageContents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetchedPages, setIsFetchedPages] = useState(false)

  const { dbId, dbName, hostName, routesData, menuName } = useContext(ClientContext)
  
  useEffect(() => {
    if(dbId){
      if(dbId){
        getPageObject();
      }
    }
  }, [dbId]);

  useEffect(() => {
    getPageContentObject();
  }, [isFetchedPages])
  
  var items = window.location.pathname;
  var path = items.split('/')
  var pathname = path[path.length-1]
  
  const pages = menuName.find(e => e.RoutePath === pathname);
  
  const [getPageObject] = useLazyQuery(GET_OBJECT_LIST_QUERY, {
    variables: {
      request: {
        tableName: "pages",
        filters: [
          {
            name: "pageName",
            value: pages.MenuName,
            type: "STRING",
            operator: "LIKE",
          },
        ],
        databaseId: dbId,
        properties: GQL_PAGES_GET_VARIABLE
      },
    },
    onCompleted: (d) => {
      if (d) {
        let items = getDynamoPagedItems(d)
        let obj = items[items.length -1]
        if (obj) {
          setTitle(obj.Title);
          setSliderText(obj.HeaderImageText);
        }
        setPageObj(obj);
        setIsFetchedPages(true)
      }
    },
    onError: (e) => {
      console.log(e);
      setPageObj(null);
    },
  });

  const [getPageContentObject] = useLazyQuery(GET_OBJECT_LIST_QUERY, {
    variables: {
      request: {
        tableName: "pageContent",
        pageSize: 1000,
        filters: [
          {
            name: "pageName",
            value: pages.MenuName,
            type: "STRING",
            operator: "LIKE",
          },
        ],
        databaseId: dbId,
        properties: GQL_PAGE_CONTENT_GET_VARIABLE
      },
    },
    onCompleted: (d) => {
      if (d) {
        let items = getDynamoPagedItems(d);
        let st = items.sort((a, b) => parseInt(a.ContentSerial) - parseInt(b.ContentSerial));
        setPageContents(st);
        setIsLoading(false);
      }
    },
    onError: (e) => {
      console.log(e);
      setIsLoading(false);
      setPageContentObj(null);
    },
  });

  return (
      <>
          {/* <h1>DynamicPage</h1> */}
      {design === 1 && (
        <>
          {isLoading &&
            <LoaderOne/>
          }

          {!isLoading && 
            <DynamicPageOne
                pageObject={pageObj}
                title={title}
                sliderText={sliderText}
                pageContents={pageContents}
            />
          }
        </>
      )}
    </>
  );
}

export default DynamicPage;
