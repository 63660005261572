

import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {Grid} from "@mui/material";

function LoaderOne(props){
    return(
        <div>
            <Grid container justifyContent={"center"}>
                <Grid item md={12} container justifyContent={"center"}>
                    <div style={{marginTop:"300px", height:"100vh"}}>
                        <CircularProgress color={'primary'}/>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default LoaderOne