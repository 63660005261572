import { CardActionArea } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

export default function BasicCard({ src, title, description, action, textAlign }) {
    return (
        <Card sx={{ borderRadius: 0.7 }}>
            <CardActionArea>
                <CardMedia
                    sx={{ margin: 0, width: 1, borderRadius: 0.7 }}
                    component="img"
                    image={src}
                    alt={title}
                />
                <CardContent>
                    <Typography gutterBottom variant="h4" component="div" textAlign={textAlign}>
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {description}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
