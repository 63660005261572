import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import RaisedBlogCard from "examples/Cards/BlogCards/RaisedBlogCard";
import MKTypography from "layouts/basic/components/MKTypography";
import BasicCard from "layouts/basic/GeneralCard/BasicCard";
import MKBox from "../../../../components/MKBox";

function HomeOne(props) {
  const { pageObject, title, sliderText, pageContents } = props;

  return (
    <>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: { lg: -8, md: -8, sm: -4, xs: -4 },
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
            rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          overflow: "hidden",
        }}
      >
        <MKBox component="section" py={6}>
          <Container>
            <Grid container alignItems="center" spacing={3}>
              {pageContents.map((e, i) => {
                if (e.ContentType === "2") {
                  return (
                    <>
                      <Grid
                        key={`${i}-home-one`}
                        item
                        sm={12}
                        lg={e.GridSize}
                        container
                        direction="column"
                        justifyContent={e.GridContainerProps}
                        alignItems={e.GridContainerProps}
                      >
                        <RaisedBlogCard
                          image={e.ImageUrl}
                          title={e.Title}
                          description={e.Description}
                          textAlign={e.GridContainerProps}
                        // action={{
                        //   type: "internal",
                        //   route: "/sections/page-sections/general-cards",
                        //   color: "info",
                        //   label: "More about us",
                        // }}
                        />
                      </Grid>
                    </>
                  )
                }
                else if (e.ContentType === "3") {
                  return (
                    <>
                      <Grid
                        key={`${i}-home-one`}
                        item
                        sm={12}
                        lg={e.GridSize}
                        container
                        direction="column"
                        justifyContent={e.GridContainerProps}
                        alignItems={e.GridContainerProps}
                      >
                        <BasicCard
                          src={e.ImageUrl}
                          title={e.Title}
                          description={e.Description}
                          textAlign={e.GridContainerProps}
                        />
                      </Grid>
                    </>
                  )
                }
                else {
                  if (
                    e.GridContainerProps === "center" ||
                    e.GridContainerProps === "flex-end"
                  ) {
                    return (
                      <>
                        <Grid
                          key={`${i}-home-one`}
                          item
                          sm={12}
                          lg={e.GridSize}
                          container
                          direction="column"
                          justifyContent={e.GridContainerProps}
                          alignItems={e.GridContainerProps}
                        >
                          <MKTypography variant={e.HeaderType} my={1}>
                            {e.Title}
                          </MKTypography>
                          <MKTypography
                            variant="body2"
                            color="text"
                            mb={1}
                            textAlign="justify"
                          >
                            {e.Description}
                          </MKTypography>
                          {e.ImageUrl && (
                            <MKBox position="relative" my={1}>
                              <MKBox
                                component="img"
                                src={e.ImageUrl}
                                alt="macbook"
                                width="100%"
                              />
                            </MKBox>
                          )}
                        </Grid>
                      </>
                    );
                  }
                  else {
                    return (
                      <>
                        <Grid
                          key={`${i}-home-one`}
                          item
                          sm={12}
                          lg={e.GridSize}
                          direction="column"
                          container
                          justifyContent="flex-start"
                        >
                          <MKTypography variant="h3" my={1}>
                            {e.Title}
                          </MKTypography>
                          <MKTypography
                            variant="body2"
                            color="text"
                            mb={1}
                            textAlign="justify"
                          >
                            {e.Description}
                          </MKTypography>
                          {e.ImageUrl && (
                            <MKBox position="relative" my={1}>
                              <MKBox
                                component="img"
                                src={e.ImageUrl}
                                alt="macbook"
                                width="100%"
                              />
                            </MKBox>
                          )}
                        </Grid>
                      </>
                    );
                  }
                }
              })}
            </Grid>
          </Container>
        </MKBox>
      </Card>
    </>
  );
}
export default HomeOne;
