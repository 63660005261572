import { useLazyQuery } from '@apollo/client';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Menu, MenuItem } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { GET_OBJECT_LIST_QUERY, GQL_GET_SITECONFIGS } from 'constants/gql-constants';
import { ClientContext } from 'contexts/ClientContext';
import { getDynamoPagedItems } from 'lib/dynamo-db-helper';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Link } from "react-router-dom";

const NavbarOne = (props) => {
  const { dbId, dbName, hostName, routesData } = useContext(ClientContext);
  const [siteinfo, setSiteInfo] = useState([]);
  const [navData, setNavData] = useState([]);
  const [hovering, setHovering] = useState(false);

  let currentlyHovering = false;

  const useStyles = makeStyles({
    popOverRoot: {
      pointerEvents: hovering ? 'fill' : 'none',
    }
  });

  useEffect(() => {
    if (dbId) {
      getSiteConfigs({
        variables: {
          request: {
            tableName: "siteConfigs",
            databaseId: dbId,
            properties: GQL_GET_SITECONFIGS,
          },
        }
      })
    }
  }, [dbId])


  const [getSiteConfigs] = useLazyQuery(GET_OBJECT_LIST_QUERY, {
    onCompleted: (d) => {
      if (d) {
        let items = getDynamoPagedItems(d);
        setSiteInfo(items);
      }
    },
    onError: (e) => {
      console.log(e);
    },
  });

  useEffect(() => {
    if (routesData) {
      let result = []

      routesData.filter(b => b.parent === "").forEach(a => {
        let childs = routesData.filter(p => p.parent === a.key)

        let o = {
          "key": a.key,
          "name": a.name,
          "route": a.route,
          "childs": childs.map(c => ({
            "key": c.key,
            "name": c.name,
            "route": c.route
          }))
        }
        result.push(o)
      })

      setNavData(result);
      let anchorEls = result.map(_ => null)
      setAnchorElNav(anchorEls);
    }
  }, [routesData])

  const styles = useStyles();
  const [anchorElNav, setAnchorElNav] = React.useState([]);

  const isOpen = (i) => Boolean(anchorElNav[i]);

  const handleOpenNavMenu = (event, index) => {
    let d = [...anchorElNav]
    if (d[index] !== event.currentTarget) {
      d[index] = event.currentTarget;
      setAnchorElNav(d);
    }
  };

  function handleHover() {
    setHovering(true);
    currentlyHovering = true;
  }


  const handleCloseNavMenu = (i) => {
    setHovering(false);
    let d = [...anchorElNav];
    d[i] = null;
    setAnchorElNav(d);
  };

  function handleCloseHover(i) {
    currentlyHovering = false;
    setTimeout(() => {
      if (!currentlyHovering) {
        handleCloseNavMenu(i);
      }
    }, 50);
  }

  const Logo = siteinfo.map((e, i) => {
    return e.LogoUrl
  })

  return (
    <AppBar>
      <Container maxWidth="xl" position="sticky"
        sx={{ backgroundColor: "#fff", borderRadius: 3, opacity: 0.8, marginTop: "20px" }}>
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <Link to="/home">
              <img
                src={Logo}
                alt="logo"
                height={65}
              />
            </Link>

          </Typography>


          {/* <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page, i) => {
                if (page.toLowerCase() === 'footer') {
                  return (
                    <MenuItem key={page} onClick={handleCloseNavMenu}>
                      <Link to={() => `/${pathHandler(i)}`}><Typography textAlign="center">
                        {""}
                      </Typography></Link>
                    </MenuItem>
                  )
                } else {
                  return (
                    <MenuItem key={page} onClick={handleCloseNavMenu}>
                      <Link to={() => `/${pathHandler(i)}`}><Typography textAlign="center">
                        {page}
                      </Typography></Link>
                    </MenuItem>
                  )
                }

              })}
            </Menu>
          </Box> */}
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <Link to="/home">
              <img
                src={Logo}
                alt="logo"
                height={55}
              />
            </Link>
          </Typography>
          <Box sx={{ flexGrow: 1, justifyContent: 'flex-end', display: { xs: 'none', md: 'flex' } }}>
            {
              navData && navData.map((data, i) => {
                if (data.childs.length > 0) {
                  return (
                    <React.Fragment key={data.key}>
                      <Button
                        aria-label="more"
                        id={`basic-btn-${data.key}`}
                        aria-controls={open ? `long-menu-${data.key}` : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={(e) => handleOpenNavMenu(e, i)}
                        onMouseOver={(e) => handleOpenNavMenu(e, i)}
                        onMouseLeave={() => handleCloseHover(i)}
                        sx={{ color: '#000' }}
                      >
                        {data.key}
                        <KeyboardArrowDownIcon fontSize='large' />
                      </Button>
                      <Menu
                        id={`long-menu-${data.key}`}
                        MenuListProps={{
                          'aria-labelledby': `basic-btn-${data.key}`,
                          onMouseEnter: handleHover,
                          onMouseLeave: () => handleCloseHover(i),
                          style: { pointerEvents: "auto" },
                        }}
                        anchorEl={anchorElNav[i]}
                        open={isOpen(i)}
                        onClose={() => handleCloseNavMenu(i)}
                        PaperProps={{
                          style: {
                            maxHeight: 100 * 4.5,
                          },
                        }}
                        getContentAnchorEl={null}
                        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                        PopoverClasses={{
                          root: styles.popOverRoot,
                        }}
                      >
                        {
                          data.childs.map((d, j) => (
                            <Link to={d.route} key={j}>
                              <MenuItem onClick={() => handleCloseNavMenu(i)}>{d.key}</MenuItem>
                            </Link>
                          ))
                        }
                      </Menu>
                    </React.Fragment>
                  )
                }
                else {
                  return (
                    <Link to={data.route} key={i}>
                      <Button
                        onClick={() => handleCloseNavMenu(i)}
                        sx={{ my: 2, color: '#000', display: 'block' }}
                      >
                        {data.key}
                      </Button>
                    </Link>
                  )
                }
              })
            }
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default NavbarOne;
