import FooterPage from "../basic/footer/FooterPage";
import CallUs from "./CallUsBtn/CallUs";
import Facebook from "./CallUsBtn/Facebook";
import MKBox from "./components/MKBox";
import NavbarOne from "./navbar/NavbarOne";
import { getHostName } from "./pages/ContactUs/Designs/DesignOne/ContactUsOne";
function BasicLayout(props) {
  return (
    <div>
      <NavbarOne />
      {props.children}
      <CallUs />
      {getHostName() === "www.vehiclesolution.net" &&
        <Facebook />
      }
      <MKBox pt={6} px={1} mt={6}>
        <FooterPage />
      </MKBox>
    </div>
  );
}
export default BasicLayout;
