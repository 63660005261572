import { useLazyQuery } from "@apollo/client";
import {
  GET_OBJECT_LIST_QUERY,
  GQL_PAGES_GET_VARIABLE,
  GQL_PAGE_CONTENT_GET_VARIABLE
} from "constants/gql-constants";
import { ClientContext } from "contexts/ClientContext";
import { useContext, useEffect, useState } from "react";
import { getDynamoPagedItems } from "../lib/dynamo-db-helper";
import LoaderOne from "../layouts/basic/loader/LoaderOne";
import GalleryDesignOne from "../layouts/basic/pages/Gallery/Designs/DesignOne/GalleryDesignOne";


function Gallery() {
  const [design, setDesign] = useState(1);
  const {dbId, dbName, hostName} = useContext(ClientContext)
  const [pageObj, setPageObj] = useState(null);
  const [title, setTitle] = useState("");
  const [pageContentObject, setPageContentObj] = useState("");
  const [sliderText, setSliderText] = useState("");
  const [pageContents, setPageContents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [isFetchedPages, setIsFetchedPages] = useState(false)

  useEffect(() => {
    if(dbId){
      getPageObject();
    }
  }, [dbId]);

  useEffect(() => {
    getPageContentObject();
  },[isFetchedPages])

  const [getPageObject] = useLazyQuery(GET_OBJECT_LIST_QUERY, {
    variables: {
      request: {
        tableName: "pages",
        filters: [
          {
            name: "pageName",
            value: "GALLERY",
            type: "STRING",
            operator: "LIKE",
          },
        ],
        databaseId: dbId,
        properties: GQL_PAGES_GET_VARIABLE,
      },
    },
    onCompleted: (d) => {
      if (d) {
        let items = getDynamoPagedItems(d)
        let obj = items[items.length -1]

        if (obj) {
          setTitle(obj.Title);
          setSliderText(obj.HeaderImageText);
        }
        setPageObj(obj);
        setIsFetchedPages(true)
      }
    },
    onError: (e) => {
      console.log(e);
      setPageObj(null);
    },
  });

  const [getPageContentObject] = useLazyQuery(GET_OBJECT_LIST_QUERY, {
    variables: {
      request: {
        tableName: "pageContent",
        pageSize: 1000,
        filters: [
          {
            name: "pageName",
            value: "GALLERY",
            type: "STRING",
            operator: "LIKE",
          },
        ],
        databaseId: dbId,
        properties: GQL_PAGE_CONTENT_GET_VARIABLE,
      },
    },
    onCompleted: (d) => {
      if (d) {
        let items = getDynamoPagedItems(d);
        let st = items.sort((a, b) => parseInt(a.ContentSerial) - parseInt(b.ContentSerial));
        setPageContents(st);
        setIsLoading(false);
      }
    },
    onError: (e) => {
      console.log(e);
      setIsLoading(false);
      setPageContentObj(null);
    },
  });

  return (
    <>
      {design === 1 && (
        <>
          {isLoading && <LoaderOne />}

          {!isLoading && (
            <GalleryDesignOne
              pageObject={pageObj}
              title={title}
              sliderText={sliderText}
              pageContents={pageContents}
            />
          )}
        </>
      )}
    </>
  );
}

export default Gallery;
