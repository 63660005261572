import { useLazyQuery } from "@apollo/client";
import { ClientContext } from "contexts/ClientContext";
import { useContext, useEffect, useState } from "react";

import { GET_OBJECT_LIST_QUERY } from "../../../constants/gql-constants";
import { getCellValue, getDynamoPagedItems } from "../../../lib/dynamo-db-helper";

//Page designs
import FooterOne from "../footer/DesignOne/FooterOne";

const FooterPage = () => {
  const [design, setDesign] = useState(1);
  const [pageContents, setPageContents] = useState([]);
  const [pageObj, setPageObj] = useState(null);
  const [title, setTitle] = useState("");
  const [gridSize, setGridSize] = useState("");
  // const [isLoading, setIsLoading] = useState(true)

  const { dbId, dbName, hostName } = useContext(ClientContext);

  useEffect(() => {
    if(dbId){
      getPageObject();
      getPageContentObject();
    }
  }, [dbId]);

  const [getPageObject] = useLazyQuery(GET_OBJECT_LIST_QUERY, {
    variables: {
      request: {
        tableName: "footer",
        databaseId: dbId,
        properties: [
          {
            name: "PageName",
          },
          {
            name: "footerStyles",
          },
          {
            name: "NumberOfGrid",
          },
        ],
      },
    },
    onCompleted: (d) => {
      if (d) {
        let obj =
          d.objectList &&
          d.objectList.items &&
          d.objectList.items[d.objectList.items.length - 1];
        if (obj) {
          setTitle(getCellValue(obj.properties, "PageName"));
          setGridSize(getCellValue(obj.properties, "NumberOfGrid"));
        }
        setPageObj(obj);
      }
    },
    onError: (e) => {
      console.log(e);
      setPageObj(null);
    },
  });

  const [getPageContentObject] = useLazyQuery(GET_OBJECT_LIST_QUERY, {
    variables: {
      request: {
        tableName: "footerContent",
        databaseId: dbId,
        properties: [
          {
            name: "ContentType",
          },
          {
            name: "Content",
          },
          {
            name: "Link",
          },
          {
            name: "LinkType",
          },
          {
            name: "Icon",
          },
          {
            name: "Grid",
          },
          {
            name: "ContentSerial",
          },
          {
            name: "ContentStyles",
          },
        ],
      },
    },
    onCompleted: (d) => {
      if (d) {
        let items = getDynamoPagedItems(d);
        let st = items.sort((a, b) => (a.ContentSerial) - (b.ContentSerial));
        setPageContents(st);
      }
    },
    onError: (e) => {
      console.log(e);
      // setIsLoading(false);
      // setPageContentObj(null);
    },
  });

  return (
    <>
      {design === 1 && (
        <>
          {pageContents && (
            <FooterOne
              pageObject={pageObj}
              title={title}
              gridSize={gridSize}
              pageContents={pageContents}
            />
          )}
        </>
      )}
    </>
  );
};

export default FooterPage;
