import CallIcon from '@mui/icons-material/Call';
// import { Container } from '@mui/material';
// import Tooltip from '@mui/material/Tooltip';
// import MKButton from 'components/MKButton';
import { ClientContext } from "contexts/ClientContext";
import { useContext } from "react";
import "./CallUs.css";

const CallUs = () => {
    const { formData } = useContext(ClientContext);
    const contactNumber = formData && formData.map((e) => { return e.ContactNumber })


    return (
        <div className="call-buton">
            <a className="cc-calto-action-ripple" href={`tel:${contactNumber}`}><CallIcon fontSize='large' />
            </a>
            {/* <Container>
                <Tooltip sx={{ borderRedius: 80 }}>
                    <a class="cc-calto-action-ripple" href="tel:01792310070"><MKButton variant="gradient" color="info" sx={{ float: "right" }}><CallIcon /><span class="num">Call Us</span>
                    </MKButton></a>
                </Tooltip>
            </Container> */}
        </div>
    )
}

export default CallUs