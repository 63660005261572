// react-router-dom components
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from '@mui/icons-material/Google';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PinterestIcon from '@mui/icons-material/Pinterest';
import TelegramIcon from '@mui/icons-material/Telegram';
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import YouTubeIcon from '@mui/icons-material/YouTube';
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// Material Kit 2 PRO React components
import MKBox from "layouts/basic/components/MKBox";
import { Link } from "react-router-dom";

function FooterOne(props) {
  const { pageObject, pageContents, gridSize, title } = props;

  function getIcon(name) {
    let icon = "";

    switch (name) {
      case "google":
        icon = <GoogleIcon />;
        break;
      case "facebook":
        icon = <FacebookIcon />;
        break;
      case "twitter":
        icon = <TwitterIcon />;
        break;
      case "linkdin":
        icon = <LinkedInIcon />;
        break;
      case "instagram":
        icon = <InstagramIcon />;
        break;
      case "youtube":
        icon = <YouTubeIcon />;
        break;
      case "whatsapp":
        icon = <WhatsAppIcon />;
        break;
      case "telegram":
        icon = <TelegramIcon />;
        break;
      case "pinterest":
        icon = <PinterestIcon />;
        break;
      case "github":
        icon = <GitHubIcon />;
        break;
      default:
        icon = "";
    }
    return icon;
  }

  let arr = gridSize.split("-");

  return (
      <MKBox component="footer">
        <Container>
          <Grid container spacing={3}>
            {arr.map((g, gi) => {
              return (
                  <Grid key={`${gi}-footer-one`} item lg={arr[gi + 1]} sm={12}>
                    {pageContents.map((item, i) => {
                      return (
                          <div key={`${i}-footer1-item`}>
                            {parseInt(item.Grid) === gi + 1 && (
                                <div key={`cell-${gi}-${i}`}>
                                  {item.ContentType === "Title" && (
                                      <h3>{item.Content}</h3>
                                  )}
                                  {item.ContentType === "Description" && (
                                      <p style={{fontSize: 16, textAlign: "justify", lineHeight: 1.5}}>{item.Content}</p>
                                  )}
                                  {item.ContentType === "Subtitle" && (
                                      <h4>{item.Content}</h4>
                                  )}
                                  {item.ContentType === "Link" && (
                                      <>
                                        {item.LinkType === "Internel" ? (
                                            <Link to={item.Link}>
                                              <p style={{ color: "#344767",fontSize: 16 }}>
                                                <span>{getIcon(item.Icon)}</span>
                                                {item.Content}
                                              </p>
                                            </Link>
                                        ) : (
                                            <a href={item.Link} target="blank" style={{ color: "#344767",fontSize: 16 }}>
                                              <span>{getIcon(item.Icon)}</span>
                                              {item.Content}
                                            </a>
                                        )}
                                      </>
                                  )}
                                </div>
                            )}
                          </div>
                      );
                    })}
                  </Grid>
              );
            })}
          </Grid>
        </Container>
      </MKBox>
  );
}

// Typechecking props for the DefaultFooter
// FooterOne.propTypes = {
//     content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
// };

export default FooterOne;
