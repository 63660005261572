//query
import { gql } from "@apollo/client";


export const GET_DB = gql`
query($request: DynamoDatabaseGetRequest!){
  database(request: $request){
    id
    name
    active
  }
}`

export const GET_OBJECT_LIST_QUERY = gql`
  query ($request: DynamoObjectListRequest!) {
    objectList(request: $request) {
      page
      pageSize
      totalCount
      items {
        objectId
        tableId
        name
        properties {
          propertyId
          name
          value
        }
      }
    }
  }
`;

//mutation

//variables

export const GQL_GET_DB_VARIABLE = {

}

export const GQL_PAGES_GET_VARIABLE = [
  {
    name: "Title",
  },
  {
    name: "HeaderImageText",
  },
  {
    name: "HeaderImageUrl",
  },
  {
    name: "HeaderImageUrl",
  },
  {
    name: "PageName",
  },
];

export const GQL_PAGE_CONTENT_GET_VARIABLE = [
  {
    name: "PageName",
  },
  {
    name: "HeaderText",
  },
  {
    name: "HeaderType",
  },
  {
    name: "Title",
  },
  {
    name: "SubTitle",
  },
  {
    name: "Description",
  },
  {
    name: "ImageUrl",
  },
  {
    name: "GridSize",
  },
  {
    name: "GridContainerProps",
  },
  {
    name: "ContentSerial",
  },
  {
    name: "ContainerStyles",
  },
  {
    name: "ContentStyles",
  },
  {
    name: "ContentType",
  },
];
export const GQL_SLIDER_CONTENT_GET_VARIABLE = [
  {
    "name": "ImageUrl"
  },
  {
    "name": "PageName"
  },
  {
    "name": "SliderText"
  },
  {
    "name": "SliderTextLink"
  },

  {
    "name": "SliderSubText"
  },
  {
    "name": "ContentSerial"
  },
  {
    "name": "ContentStyles"
  }
];
export const GQL_GET_ROUTES = [
  {
    "name": "MenuName"
  },
  {
    "name": "Parent"
  },
  {
    "name": "RoutePath"
  },
  {
    "name": "SliderTextLink"
  },
  {
    "name": "NavSerial"
  },
  {
    "name": "ComponentName"
  }
];
export const GQL_GET_SITECONFIGS = [
  {
    "name": "LogoUrl"
  },
 {
    "name": "ContactEmail"
  },
  {
    "name": "ContactNumber"
  },
  {
    "name": "Template"
  },
  {
    "name": "GPSCoordinates"
  }
];
export const GQL_GET_CONFIG_DATA = [
  {
    name: "LogoUrl",
  },
  {
    name: "ContactEmail",
  },
  {
    name: "ContactNumber",
  },
  {
    name: "Template",
  },
  {
    name: "GPSCoordinates",
  },

];